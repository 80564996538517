<!-- Feature Start -->
<!-- <section class="section "> -->
	<div class="container">
		<div class="row justify-content-center" >
			<div class="col-12 text-center" >
				<div class="section-title mb-4 pb-2" >
					<h4 class="title mb-4">What We Do ?</h4>
					<p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary font-weight-bold">Coremonk</span> that can provide everything you need to improve your business.</p>
				</div>
			</div><!--end col-->
		</div><!--end row-->

		<div class="row">
			<div class="col-md-4 col-12 mt-5">
				<div class="features text-center">
					<div class="image position-relative d-inline-block">
						<img src="images/icon/pen.svg" draggable="false" class="avatar avatar-small" alt="">
					</div>

					<div class="content mt-4">
						<h4 class="title-2">Design</h4>
						<p class="text-muted mb-0 text-justify">We engage in board design, solutions based on Microcontrollers / Processors that includes high and low speed designs.</p>
					</div>
				</div>
			</div><!--end col-->
			
			<div class="col-md-4 col-12 mt-5">
				<div class="features text-center">
					<div class="image position-relative d-inline-block">
						<img src="images/icon/video.svg" draggable="false" class="avatar avatar-small" alt="">
					</div>

					<div class="content mt-4">
						<h4 class="title-2">Development</h4>
						<p class="text-muted mb-0 text-justify">With the strong experience in the embedded domain, we provide software development solutions and services for Embedded Systems, IOT and other related solutions.</p>
					</div>
				</div>
			</div><!--end col-->
			
			<div class="col-md-4 col-12 mt-5">
				<div class="features text-center">
					<div class="image position-relative d-inline-block">
						<img src="images/icon/intellectual.svg" draggable="false" class="avatar avatar-small" alt="">
					</div>

					<div class="content mt-4">
						<h4 class="title-2">Smart & Secure IOT </h4>
						<p class="text-muted mb-0 text-justify">From increasing the safety of roads, cars and homes, to fundamentally improving the way we manufacture and consume products, IoT solutions provide valuable data and insights that improve the way we work and live.</p>
					</div>
				</div>
			</div><!--end col-->

			<div class="col-md-4 col-12 mt-5">
				<div class="features text-center ">
					<div class="image position-relative d-inline-block">
						<img src="images/icon/user.svg" draggable="false" class="avatar avatar-small" alt="">
					</div>

					<div class="content mt-4">
						<h4 class="title-2">Corporate Trainings</h4>
						<p class="text-muted mb-0 text-justify">We offer corporate trainings from as basic as programming to as complex as Product Design. Our unique programs are designed to develop practical skills which will improve the efficiency and productivty of engineers</p>
					</div>
				</div>
			</div><!--end col-->
			
			<div class="col-md-4 col-12 mt-5">
				<div class="features text-center ">
					<div class="image position-relative d-inline-block">
						<img src="images/icon/calendar.svg" draggable="false" class="avatar avatar-small" alt="">
					</div>

					<div class="content mt-4">
						<h4 class="title-2">Automation Products</h4>
						<p class="text-muted mb-0 text-justify">We are developing products for the Automation. With our strong expertise on Automation technology, we are working on products to cater to automated test equipment (ATE) and Industrial Automation .</p>
					</div>
				</div>
			</div><!--end col-->
			
			<div class="col-md-4 col-12 mt-5">
				<div class="features text-center ">
					<div class="image position-relative d-inline-block">
						<img src="images/icon/sand-clock.svg" draggable="false" class="avatar avatar-small" alt="">
					</div>

					<div class="content mt-4">
						<h4 class="title-2">Embedded Products</h4>
						<p class="text-muted mb-0 text-justify">We are developing products for the IoT domains. With our strong expertise on Sensors, Data Security and HMI, we are working on products to cater to Medical, Agriculture, Industrial Automation and Home Automation.</p>
					</div>
				</div>
			</div><!--end col-->
		</div><!--end row-->
	</div><!--end container-->
<!-- </section> -->
<!--end section-->
<!-- Feature End -->

