<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100 " >
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-12 text-center">
				<div class="page-next-level">
					<h4 class="title"> {{herotitle| uppercase}} </h4>
					<div *ngIf="isSubTitle; else NoSubTitle">
						<ul class="list-unstyled mt-4">
							<li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Updated :</span>{{datemod}}</li>
						</ul>
					</div>

					<ng-template #NoSubTitle></ng-template>

 
					<div class="page-next">
						<nav aria-label="breadcrumb" class="d-inline-block">
							<ul class="breadcrumb bg-white rounded shadow mb-0">
								<li class="breadcrumb-item" routerLinkActive="active" *ngFor="let brdcs of breadcrumbs"><a href="{{brdcs.root}}" routerLink={{brdcs.root}} >{{ brdcs.name }}</a></li>
							</ul>
						</nav>
					</div>
				</div>
			</div><!--end col-->
		</div><!--end row-->
	</div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
	<div class="shape overflow-hidden text-white">
		<svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
		</svg>
	</div>
</div>
<!--Shape End-->