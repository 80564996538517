<!-- Hero Start -->
<!-- style="background: url('images/bg/bg-circle-effect.png') center center; overflow: none; " -->
<section class="bg-half-170  d-table w-100 margin-bottom-0" id="home" >
	<div class="container" >
		<div class="row align-items-center">
			<div class="col-lg-7 col-md-7">
				<div class="title-heading mt-4">
					<h1 class="heading mb-3">Our Creativity Is Your <span class="text-primary">Success</span> </h1>
					<p class="para-desc text-muted">Embedded Solutions for the IOT Domains, innovative product deliver to your requirenment. </p>
					<div class="mt-4">
						<a href="/contact-us" routerLink="/contact-us" class="btn btn-primary mt-2 mr-2"><i class="mdi mdi-email"></i> Contact us</a>
						<a href="#projects" class="btn btn-outline-primary mt-2"><i class="mdi mdi-book-outline"></i> Projects</a>
					</div>
				</div>
			</div><!--end col-->

			<div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
				
				<img src="images/illustrator/Startup.svg" alt="product overview" draggable="false" style="center center;" class="img-fluid mover">
			</div><!--end col-->
		</div><!--end row-->
	</div><!--end container-->
</section><!--end section-->
<!-- Hero End -->

