import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-default',
  templateUrl: './services-default.component.html',
  styleUrls: ['./services-default.component.css']
})
export class ServicesDefaultComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
