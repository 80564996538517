<!-- Work & Project Start -->
<!-- <section class="section bg-light"> -->
	<div id="projects" class="container">
		<div class="row justify-content-center">
			<div class="col-12 text-center">
				<div class="section-title mb-4 pb-2">
					<h4 class="title mb-4">Our Work, Project & Portfolio </h4>
					<p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary font-weight-bold">Coremonk</span> that can provide everything you need to improve your business.</p>
				</div>
			</div><!--end col-->
		</div><!--end row-->
		
		<div class="container">
			<div class="row">
				<ul class="col container-filter list-unstyled categories-filter text-center mb-0" id="filter">
					<li class="list-inline-item"><a class="categories border d-block text-dark rounded" [ngClass]="{'active': tabIndex == 1}" (click)="FilterAll(1)">All</a></li>
					<li class="list-inline-item"><a class="categories border d-block text-dark rounded" [ngClass]="{'active': tabIndex == 2}" (click)="FilterAll(2)">Training & Events</a></li>
					<li class="list-inline-item"><a class="categories border d-block text-dark rounded" [ngClass]="{'active': tabIndex == 3}" (click)="FilterAll(3)">System Design</a></li>
					<li class="list-inline-item"><a class="categories border d-block text-dark rounded" [ngClass]="{'active': tabIndex == 4}" (click)="FilterAll(4)">Products</a></li>
					<li class="list-inline-item"><a class="categories border d-block text-dark rounded" [ngClass]="{'active': tabIndex == 5}" (click)="FilterAll(5)">Software</a></li>
				</ul>
			</div><!--end row-->

			<div class="row projects-wrapper" style="position: relative;">
				<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2" *ngFor="let projectslist of projectslists">
					<div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
						<div class="card-body p-0">
							<img src="{{projectslist.img}}" class="img-fluid rounded" alt="work-image">
							<div class="overlay-work bg-dark"></div>
							<div class="content">
								<a href="javascript:void(0)" class="title text-white d-block font-weight-bold">{{projectslist.title}}</a>
								<small class="text-light">{{projectslist.course}}</small>
							</div>
							<div class="client">
								<small class="text-light user d-block"><i class="mdi mdi-at"></i>{{projectslist.location}}</small>
								<small class="text-light date"><i class="mdi mdi-calendar-check"></i>{{projectslist.date}}</small>
							</div>
						</div>
					</div>
				</div><!--end col-->
			</div><!--end row-->
		</div><!--end container-->
		<!-- Work & Project End -->
	</div>
<!-- </section> -->