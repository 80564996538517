import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

	tabIndex = 1;
	projectslists: any[] = [
		{"img": "images/projects/Training-UAV-chennai-3.jpg","title": "UAV","course": "Design & Development","location": "Chennai","date": "02-AUG-2018",},
		{"img": "images/projects/Training-drone-chennai-2.jpg","title": "UAV","course": "Design & Development","location": "Chennai","date": "02-AUG-2018",},
		{"img": "images/projects/Training-drone-chennai-1.jpg","title": "UAV","course": "Design & Development","location": "Chennai","date": "02-AUG-2018",},
		{"img": "images/projects/Training-sapthagiri-engineering-college.jpg","title": "Software dev","course": "Development","location": "Sapthagiri Engineering College","date": "09-SEP-2019",},
		
	]

	FilterAll(expression) {			
		switch(expression) { 
			case 1: { 
				this.projectslists = [
					{"img": "images/projects/Training-UAV-chennai-3.jpg","title": "UAV","course": "Design & Development","location": "Chennai","date": "02-AUG-2018",},
					{"img": "images/projects/Training-drone-chennai-2.jpg","title": "UAV","course": "Design & Development","location": "Chennai","date": "02-AUG-2018",},
					{"img": "images/projects/Training-drone-chennai-1.jpg","title": "UAV","course": "Design & Development","location": "Chennai","date": "02-AUG-2018",},
					{"img": "images/projects/Training-sapthagiri-engineering-college.jpg","title": "Software dev","course": "Development","location": "Sapthagiri Engineering College","date": "09-SEP-2019",},
					]
				this.tabIndex = 1;
			  break; 
			} 
			case 2: { 
			  //Training & Events; 
				this.projectslists = [
					{"img": "images/projects/Training-UAV-chennai-3.jpg","title": "UAV","course": "Design & Development","location": "Chennai","date": "02-AUG-2018",},
					{"img": "images/projects/Training-drone-chennai-2.jpg","title": "UAV","course": "Design & Development","location": "Chennai","date": "02-AUG-2018",},
					{"img": "images/projects/Training-drone-chennai-1.jpg","title": "UAV","course": "Design & Development","location": "Chennai","date": "02-AUG-2018",},
					{"img": "images/projects/Training-sapthagiri-engineering-college.jpg","title": "Software dev","course": "Development","location": "Sapthagiri Engineering College","date": "09-SEP-2019",},
				]
				this.tabIndex = 2;
			  break; 
			} 
			case 3: { 
			  //System Design; 
				this.projectslists = [
					//{"img": "images/projects/Training-drone-chennai-1.jpg","title": "UAV","course": "Design & Development","location": "Chennai","date": "02-AUG-2018",},
				]
				this.tabIndex = 3;
			  break; 
			}
			case 4: { 
			  //Products; 
				this.projectslists = [
					//{"img": "images/projects/Training-sapthagiri-engineering-college.jpg","title": "Software dev","course": "Development","location": "Sapthagiri Engineering College","date": "09-SEP-2019",},
				]
				this.tabIndex = 4;
			  break; 
			}
			case 5: { 
			  //Software; 
				this.projectslists = [
					//{"img": "images/projects/Training-drone-chennai-1.jpg","title": "UAV","course": "Design & Development","location": "Chennai","date": "02-AUG-2018",},
				]
				this.tabIndex = 5;
			  break; 
			}		   
			default: { 
			  //statements; 
			  break; 
			}
		} 
    }
	constructor() { }

	ngOnInit() {

	}

}
