import { Component } from '@angular/core';
import {Router,NavigationEnd, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
	title = 'help-web';
	routerstyle:boolean = true;
	
	constructor(
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		private metaService: Meta
	) {}
 
	
	ngOnInit(){
		
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
		).subscribe(() => {
 
        var rt = this.getChild(this.activatedRoute)
 
        rt.data.subscribe(data => {
          console.log(data);
          this.titleService.setTitle(data.title)
 
          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.metaService.removeTag("name='description'")
          }
		  
		  if (data.keywords) {
			this.metaService.updateTag({ name: 'keywords', content: data.keywords });

		  } else {
            this.metaService.removeTag("name='keywords'")
          }
 
          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots })
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          }
 
          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }
 
          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'")
          }
 
          if (data.ogDescription) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.metaService.removeTag("property='og:description'")
          }
 
          if (data.ogImage) {
            this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.metaService.removeTag("property='og:image'")
          }
 
 
        })
 
      })
 	}
	
	getChild(activatedRoute: ActivatedRoute) {
		if (activatedRoute.firstChild) {
			return this.getChild(activatedRoute.firstChild);
		} 
		else {
			return activatedRoute;
		}
	}
	
	onActivate(event) {
		/* console.log(this.router.url); */
		if ((this.router.url == "/about-us"))
		{
			this.routerstyle = true;
		}
		else 
		{
			this.routerstyle = false;
		}
		
		if (isPlatformBrowser(this.platformId)) {
			let scrollToTop = window.setInterval(() => {
			let pos = window.pageYOffset;
			if (pos > 0) {
			  window.scroll(0,0);
			} else {
			  window.clearInterval(scrollToTop);
			}
			}, 16);
		}
		else
		{
				;
		}
	}
	
}
