import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
	/* { path: '', redirectTo:'home', pathMatch:'full'}, */
	{ 	path: '', 
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
		data: {
			title: 'Coremonk Technologies Private Limited | Shape the future of mobility',
			ogTitle: 'Coremonk Technologies Private Limited| Shape the future of mobility',
			descrption: "Coremonk Technologies Private Limited,Embedded Software Engineering Services - Developing both embedded hardware and software, IOT for Industry, IOT for Agriculture, Smart City, Smart Home, and many more IOT stuff's.",
			ogUrl:'https://coremonk.com',
			ogImage:'./favicon.ico',
		}
	},
	{ 	path: 'about-us', 
		loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule),
		data: {
			title: 'Coremonk Technologies Private Limited | About us | Shape the future of mobility',
			ogTitle: 'About us, Coremonk Technologies Private Limited | Shape the future of mobility',
			descrption: 'Embedded Software Engineering Services - Developing both embedded hardware and software has been forte of Coremonk Technologies Private Limited since two 2years. Coremonk has developed several embedded Real Time applications for hand held navigation systems, wrist wearable computer and IOT application using RTOS like Integrity, across various processor architecture.',
			keywords:'Bengaluru based company, Coremonk Technologies Private Limited, Bengaluru, peenya 2nd stage, software, system, india, karnataka.',
			ogUrl:'https://coremonk.com/about-us',
			ogImage:'./favicon.ico',
		}
	},
	{ 	path: 'contact-us', 
		loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule),
		data: {
			title: 'Coremonk Technologies Private Limited | Contact us',
			ogTitle: 'Contact us | Enabling Future of Mobility',
			descrption: 'Beyond the right culture and portfolio of products, we believe it is essential to have the right people, in the right frame of mind, organized in the right way.',
			keywords:'Coremonk Technologies Private Limited customer care, contact Coremonk Technologies Private Limited, Coremonk Technologies Private Limited contact number, Coremonk Technologies Private Limited customer care number, Coremonk Technologies Private Limited download support number, Coremonk Technologies Private Limited sales contact number.',
			ogUrl:'https://coremonk.com/contact-us',
			ogImage:'./favicon.ico',
		}
	}, 
	{ 	path: 'services', 
		loadChildren: () => import('./services/services.module').then(m => m.ServicesModule),
		data: {
			title: 'Coremonk Technologies Private Limited | Services',
			ogTitle: 'Services, Coremonk Technologies Private Limited | Enabling Future of Mobility',
			descrption: 'Embedded Software Engineering Services - Developing both embedded hardware and software has been forte of Coremonk Technologies Private Limited since two 2years. Coremonk Technologies Private Limited has developed several embedded Real Time applications for hand held navigation systems, wrist wearable computer and IOT application using RTOS like Integrity, across various processor architecture.',
			keywords:'Software, Hardware design, software & hardware services, IOT solutions, IOT products, Coremonk Technologies Private Limited, Electron Labs, Labs, Electron, Systems, Embedded Systems, GPS, Enterprise Solutions,Smartworks, IT Services, Digital Signal Processing and Global Positioning Systems, project management, wireless communications, enterprise management domains, Innovative Information Technology Solutions, stakeholders, Hardware Development Expertise, Operating Environments, Bengaluru based company, Coremonk Technologies Private Limited, Bengaluru, peenya 2nd stage, software, system, india, karnataka.',
			ogUrl:'https://coremonk.com/services',
			ogImage:'./favicon.ico',
		}
	}, 
	{ 	path: 'terms-of-use', 
		loadChildren: () => import('./terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule),
		data: {
			title: 'Coremonk Technologies Private Limited | Terms of Use',
			ogTitle: 'Coremonk Technologies Private Limited| ',
			ogImage:'./favicon.ico',
			/* descrption: 'Description of Terms of use', */
			ogUrl:'https://coremonk.com/terms-of-use',
		}
	},
	{ 	path: 'privacy-policy', 
		loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
		data: {
			title: 'Coremonk Technologies Private Limited | Privacy Policy',
			ogTitle: 'Coremonk Technologies Private Limited | Privacy Policy',
			/* descrption: 'Description of Coremonk Technologies Private Limited | Privacy Policy', */
			ogImage:'./favicon.ico',
			ogUrl:'https://coremonk.com/privacy-policy',
		}
	},
	{ 	path: 'terms-of-service', 
		loadChildren: () => import('./terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule),
		data: {
			title: 'Coremonk Technologies Private Limited | Terms Of Service',
			ogTitle: 'Coremonk Technologies Private Limited | Terms Of Service',
			/* descrption: 'Description of Coremonk Technologies Private Limited | Privacy Policy', */
			ogUrl:'https://coremonk.com/terms-of-service',
			ogImage:'./favicon.ico',
		}
	},

	{	path: '404',
		loadChildren: () => import('./ep404/ep404.module').then(m => m.Ep404Module) 
	},
	{ path: 'product', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) },
	
	/* {path:'404',component:Ep404Component }, */
	{path: '**', redirectTo: '/404'},
	
	];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
  /* imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})], */
  exports: [RouterModule]
})
export class AppRoutingModule { }
