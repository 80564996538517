<!-- Loader -->
<div id="preloader">
	<div id="status">
		<div class="spinner">
			<div class="double-bounce1"></div>
			<div class="double-bounce2"></div>
		</div>
	</div>
</div>
<!-- Loader -->
<app-navbar-startup [light]=routerstyle></app-navbar-startup>
<router-outlet (activate)="onActivate($event)"></router-outlet>
<app-footer-default></app-footer-default>