<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky active" >
	<div class="container">
	<div class="navbar navbar-expand-lg">
		<!-- Logo container-->
		<div>
			<a class="logo" href="#" routerLink="">
				<!-- <span class="text-primary font-weight-bold">Coremonk</span> -->
				<!-- <span class="text-danger"><i><span>&#9862;</span></i></span> -->
				<img src="./assets/images/Coremonk_logo.png" height="38" alt="">
			</a>
		</div>                 

		<!-- End Logo container-->
		<div class="menu-extras">
			<div class="menu-item">
				<!-- Mobile menu toggle-->
				<a class="navbar-toggle" (click)="toggleNavbar()">
					<div class="lines">
						<span></span>
						<span></span>
						<span></span>
					</div>
				</a>
				<!-- End mobile menu toggle-->
			</div>
		</div>
		
		<div class="collapse navbar-collapse"
		   [ngClass]="{ 'show': navbarOpen }" style="justify-content: flex-end;" >
			<!-- Navigation Menu-->   
			<ul class="navbar-nav mr-auto" class="navigation-menu" [class.nav-light]="light">
				<li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a href="#" routerLink="" (click)="nav_close()">Home</a></li>
				<li routerLinkActive="active"><a href="#" [routerLink]="['/about-us']" (click)="nav_close()">About us</a></li>
				<li routerLinkActive="active"><a routerLink="/services" (click)="nav_close()">Services</a></li>
				<li routerLinkActive="active"><a routerLink="/contact-us" (click)="nav_close()">Contact us</a></li>
			</ul><!--end navigation menu-->
		</div><!--end navigation-->
	</div><!--end container-->
	</div>
	<!--end container-->
</header><!--end header-->
<!-- Navbar End -->