import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* components */
import { ProjectComponent } from './components/projects/project/project.component';
import { HeroDefaultComponent } from './components/heros/hero-default/hero-default.component';
import { ServicesDefaultComponent } from './components/services/services-default/services-default.component';
import { HeroBreadcrumbComponent } from './components/heros/hero-breadcrumb/hero-breadcrumb.component';
//import { LottieAnimationModule } from './lottie-animation/lottie-animation.module';
//import { LottieAnimationComponent } from './lottie-animation/lottie-animation.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
  ProjectComponent,
  HeroDefaultComponent,
  ServicesDefaultComponent,
  HeroBreadcrumbComponent,
  //LottieAnimationModule,
  //LottieAnimationComponent,
  ],
  imports: [
    CommonModule,
	RouterModule,
	],
	exports: [
		ProjectComponent,
		HeroDefaultComponent,
		ServicesDefaultComponent,
		HeroBreadcrumbComponent,
		//LottieAnimationModule,
		//LottieAnimationComponent,
	],
})
export class ShareComponentsModule { }
