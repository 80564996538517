import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-navbar-startup',
  templateUrl: './navbar-startup.component.html',
  styleUrls: ['./navbar-startup.component.css']
})
export class NavbarStartupComponent implements OnInit {
	navbarOpen = false;

	@Input() light:boolean;
	constructor() { }

	ngOnInit() {
		
	}
	toggleNavbar() {
		this.navbarOpen = !this.navbarOpen;
	}
	nav_close() {
		this.navbarOpen = !this.navbarOpen;
	}
	
}
