import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-hero-breadcrumb',
  templateUrl: './hero-breadcrumb.component.html',
  styleUrls: ['./hero-breadcrumb.component.css']
})
export class HeroBreadcrumbComponent implements OnInit {
	@Input() herotitle:String;
	@Input() breadcrumbs: any[] = [];
	@Input() isSubTitle: Boolean = false;
	@Input() datemod:String;
	
	constructor() { }

	ngOnInit() {
		/* console.log(this.herotitle);
		console.log(this.breadcrumbs);
		 */
	}

}
