import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-default',
  templateUrl: './footer-default.component.html',
  styleUrls: ['./footer-default.component.css']
})
export class FooterDefaultComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  SocialMediaInfo: any[] = [
		{"icon": "uil uil-facebook-f","target":"_blank","link": "https://www.facebook.com/coremonk/"},
		{"icon": "uil uil-instagram-alt","target":"_blank","link": "https://www.instagram.com/coremonk.official/"},
		{"icon": "uil uil-linkedin","target":"_blank","link": "https://www.linkedin.com/in/coremonk"},
	]
		/* {"icon": "uil uil-whatsapp","target":"_blank","link": "#"}, */

	

}