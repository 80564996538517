import { BrowserModule, BrowserTransferStateModule,Meta  } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {APP_BASE_HREF} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import { NavbarStartupComponent } from './share-components/components/navbars/navbar-startup/navbar-startup.component';
import { FooterDefaultComponent } from './share-components/components/footers/footer-default/footer-default.component';


import { ShareComponentsModule } from './share-components/share-components.module';


@NgModule({
  declarations: [
    AppComponent,
	NavbarStartupComponent,
	FooterDefaultComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
	//BrowserTransferStateModule,
    AppRoutingModule,
	HttpClientModule,
	ShareComponentsModule,
  ],
  providers: [
	Meta,
	//{provide: APP_BASE_HREF, useValue: '/localhost/browser/'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
