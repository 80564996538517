<!-- Footer Start -->
<footer class="footer">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
				<a href="#" routerLink="" class="logo-footer">
					<!-- <span class="text-primary font-weight-bold">Coremonk</span> -->
					<!-- <span class="text-danger"><i><span>&#9862;</span></i></span> -->
					<img src="./assets/images/Coremonk_logo.png" height="44" alt="">
				</a>
				<p class="mt-4">Start working with Coremonk that can provide everything you need to improve your buisness.</p>
				<!-- <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
					<ul class="list-unstyled text-sm-right mb-0">
						<li class="list-inline-item"><a href="javascript:void(0)"><img src="images/" class="avatar avatar-ex-sm" title="American Express" alt=""></a></li>
						<li class="list-inline-item"><a href="javascript:void(0)"><img src="images/" class="avatar avatar-ex-sm" title="Discover" alt=""></a></li>
						<li class="list-inline-item"><a href="javascript:void(0)"><img src="images/" class="avatar avatar-ex-sm" title="Master Card" alt=""></a></li>
						<li class="list-inline-item"><a href="javascript:void(0)"><img src="images/" class="avatar avatar-ex-sm" title="Paypal" alt=""></a></li>
						<li class="list-inline-item"><a href="javascript:void(0)"><img src="images/" class="avatar avatar-ex-sm" title="Visa" alt=""></a></li>
					</ul>
				</div> -->
			</div><!--end col-->
			
			<div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
				<h4 class="text-light footer-head">Company</h4>
				<ul class="list-unstyled footer-list mt-4">
					<li><a href="#" routerLink="/about-us" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> About us</a></li>
					<li><a href="#" routerLink="/services" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Services</a></li>
					<li><a href="#" routerLink="/contact-us" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Contact us</a></li>
				</ul>
			</div><!--end col-->
			
			<div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
				<h4 class="text-light footer-head">Usefull Links</h4>
				<ul class="list-unstyled footer-list mt-4">
					<!-- <li><a href="product" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Products</a></li> -->
					<li><a href="./downloads/Iot-white-paper.pdf" target="_blank" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> WhitePaper</a></li>
					<li><a href="./downloads/Coremonk-Portfolio.pdf" target="_blank" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>Coremonk Portfolio</a></li>
					
				</ul>
			</div><!--end col-->

			<div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
				<h4 class="text-light footer-head">Connect with Us</h4>
				<ul class="list-unstyled social-icon social mb-0 mt-4" >
					<li style="padding-right:3%" class="list-inline-item" *ngFor="let smi of SocialMediaInfo" ><a href="{{smi.link}}" target="{{smi.target}}" class="rounded"><i class="{{smi.icon}}"></i></a></li>
				</ul><!--end icon-->
			</div><!--end col-->
		</div><!--end row-->
	</div><!--end container-->
</footer><!--end footer-->
<footer class="footer footer-bar">
	<div class="container text-center">
		<div class="row align-items-center">
			<div class="col-sm-12 col-md-6">
				<div class="text-sm-left">
					<p class="mb-0">2020-21 Coremonk. Design with<i class="mdi mdi-heart text-danger"></i> by <a href="https://coremonk.com/" target="_blank" class="text-reset">Coremonk</a>.</p>
				</div>
			</div><!--end col-->
			<div class="col-sm-12 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
				<ul class="list-unstyled text-sm-right mb-0" >
					<li style="padding-right:3%"class="list-inline-item"><a href="#" routerLink="/terms-of-use" class="text-foot"><i></i> Terms Of Use</a></li>
					<li style="padding-right:3%"class="list-inline-item"><a href="#" routerLink="/terms-of-service" class="text-foot"><i></i> Terms Of Service</a></li>
					<li style="padding-right:3%"class="list-inline-item"><a href="#" routerLink="/privacy-policy" class="text-foot"><i></i> Privacy Policy</a></li>
				</ul>
			</div>
		</div><!--end row-->
	</div><!--end container-->
</footer><!--end footer-->
<!-- Footer End -->